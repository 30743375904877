@font-face {
    font-family: 'stolzlbold';
    src: url('./assets/stolzl-bold-webfont.woff2') format('woff2'),
         url('./assets/stolzl-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'stolzlregular';
    src: url('./assets/stolzl-regular-webfont.woff2') format('woff2'),
         url('./assets/stolzl-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'stolzlmedium';
    src: url('./assets/stolzl-medium-webfont.woff2') format('woff2'),
         url('./assets/stolzl-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body, html {
    overflow-x: hidden;
    margin:0;
    padding:0;
  }

b {
    font-weight: 700;
    color: #3F4650;
    border-bottom: 2px solid #DD5C5C;
}

  .ityped-cursor {
    font-size: 3rem;
    color:#D0C4BB;
    opacity: 1;
    -webkit-animation: blink 0.3s infinite;
    -moz-animation: blink 0.3s infinite;
    animation: blink 0.3s infinite;
    animation-direction: alternate;
}
 
@keyframes blink {
    100% {
        opacity: 0;
    }
}
 
@-webkit-keyframes blink {
    100% {
        opacity: 0;
    }
}
 
@-moz-keyframes blink {
    100% {
        opacity: 0;
    }
}

.menu > .active {
    border-bottom: 7px solid #DD5C5C;
    transition: border-width 0.2s linear;
} 